import { toggleLoader } from '@actions/LoaderActions';
import { deletePartner, getPartnerConsumptionPoints, getPartners, getPaginatedPartners } from '@api/user/partners';
import PartnerRow from '@components/account/MyAccount/Partners/table/PartnersTableRow';
import { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import AddPartnerCP from './AddPartnerCP';
import PartnerCPTableRow from './table/PartnerCPTableRow';
import Pagination from '@components/common/Pagination/Pagination';
import ButtonFilters from '@components/common/Button/ButtonFilters';
import { Input } from '@components/common/Input';

const Partners = () => {
  const [list, setList] = useState<any>([]);
  const [currentPartner, setCurrentPartner] = useState<any>(null);
  const [error, setError] = useState('');
  //eslint-disable-next-line
  const [deleteError, setDeleteError] = useState('');
  const [cpList, setCPList] = useState([]);
  const [cpError, setCPError] = useState('');
  const [itemToDelete, setItemToDelete] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [partnerCode, setPartnerCode] = useState('');
  const [pod, setPod] = useState('');
  const [toggleFilters, setToggleFilters] = useState(false);
  const [CPpageNumber, setCPPageNumber] = useState(1);
  const [CPpageSize, setCPPageSize] = useState(10);
  const [CPtotalItems, setCPTotalItems] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    let payload = [
      `pageNumber=${pageNumber}`,
      `pageSize=${pageSize}`,
      partnerCode !== '' && `partnerCode=${partnerCode}`,
      pod !== '' && `pod=${pod}`
    ];

    getList(payload);
  }, [pageSize, pageNumber]);

  useEffect(() => {
    if (currentPartner !== null) {
      let payload = [
        `pageNumber=${CPpageNumber}`,
        `pageSize=${CPpageSize}`,
        `partnerCode=${currentPartner.partnerCode}`,
      ]
  
      getConsumptionPoints(payload);
    }
  }, [CPpageSize, CPpageNumber])

  const setElementsPerPage = (elements: number) => {
    setPageSize(elements);
  };

  const paginate = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const setCPElementsPerPage = (elements: number) => {
    setCPPageSize(elements);
  };

  const paginateCP = (pageNumber: number) => {
    setCPPageNumber(pageNumber);
  };

  const getList = async (payload: any) => {
    setError('');
    setCPError('');

    dispatch(toggleLoader(true));

    await getPaginatedPartners(payload)
      .then((response) => {
        if (response && response.data) {
          setList(response.data.items);
          setTotalItems(response.data.metadata.totalItemsCount);
          setPageNumber(response.data.metadata.pageIndex);
          setPageSize(response.data.metadata.pageSize);

          setIsAdding(false);
        } else {
          setError('A apărut o eroare. Vă rugăm să reveniți mai târziu.');
        }
      })
      .catch((err) => setError('A apărut o eroare. Vă rugăm să reveniți mai târziu.'));

    dispatch(toggleLoader(false));
  };

  const getConsumptionPoints = async (payload: any) => {
    setCPError('');

    dispatch(toggleLoader(true));

    await getPartnerConsumptionPoints(payload)
      .then((res) => {
        if (res && res.data && res.data.data && res.data.data.items && res.data !== null) {
          setCPList(res.data.data.items);
          setCPTotalItems(res.data.data.metadata.totalItemsCount);
          setCPPageNumber(res.data.data.metadata.pageIndex);
          setCPPageSize(res.data.data.metadata.pageSize);
          setIsAdding(false);
        } else {
          setCPError('A apărut o eroare. Vă rugăm să reveniți mai târziu.');
        }
      })
      .catch((err) => setCPError('A apărut o eroare. Vă rugăm să reveniți mai târziu.'));

    dispatch(toggleLoader(false));
  };

  const deleteClientPartner = async (partnerCode: string) => {
    dispatch(toggleLoader(true));

    await deletePartner(partnerCode)
      .then((res) => {
        if (res && res.status === 200) {
          setCurrentPartner(null);
          setCPList([]);
          setPod('');
          setPartnerCode('');
          setPageNumber(1);
          setPageSize(10);

          let payload = [`pageNumber=1`, `pageSize=10`];

          getList(payload);
          setShowModal(false);
          setIsAdding(false);
        }
      })
      .catch((err) => {
        dispatch(toggleLoader(false));
      });
  };

  const onPartnerSelect = (item: any) => {
    if (item !== currentPartner) {
      setCurrentPartner(item);

      setCPPageNumber(1);
      setCPPageSize(10);

      let payload = [
        `pageNumber=1`,
        `pageSize=10`,
        `partnerCode=${item.partnerCode}`,
      ]

      getConsumptionPoints(payload);
    }
  };

  const onPartnerDelete = (item: any) => {
    setItemToDelete(item);
    setShowModal(true);
  };

  const renderConfirmModal = () => {
    return (
      <Modal show={showModal} onHide={() => setShowModal(false)} centered backdrop={'static'}>
        <Modal.Header closeButton>
          <div>
            <h5 className="red">Șterge</h5>
          </div>
        </Modal.Header>
        <Modal.Body>Ești sigur că vrei să ștergi acest partener?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Renunță
          </Button>
          <Button onClick={() => deleteClientPartner(itemToDelete?.partnerCode)}>Șterge</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleSuccessModal = (show: boolean) => {
    setShowSuccessModal(show);
  };

  const renderSuccessModal = () => {
    return (
      <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered backdrop={'static'}>
        <Modal.Header closeButton>
          <div>
            <h5 className="red">Succes!</h5>
          </div>
        </Modal.Header>
        <Modal.Body>Locul de consum a fost adăugat cu succes.</Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setShowSuccessModal(false);
              setCurrentPartner(null);
              setCPList([]);
              setPageNumber(1);
              setPageSize(10);

              let payload = [`pageNumber=1`, `pageSize=10`];

              getList(payload);
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderFilters = () => {
    return (
      <div className="m-4">
        <Row className="justify-content-center">
          <Col xs={6} sm={6}>
            <Input
              value={partnerCode}
              label="Cod partener"
              onChange={(e) => {
                if (/^[0-9]+$/.test(e.target.value) || e.target.value === '') {
                  setPartnerCode(e.target.value);
                }
              }}
            />
          </Col>
          <Col xs={6} sm={6}>
            <Input value={pod} label="Cod loc consum" onChange={(e) => setPod(e.target.value)} />
          </Col>
        </Row>
        <Row className="justify-content-center mb-3">
          <Col xs={12} sm={9}>
            <div className="triplet-separator"></div>
          </Col>
        </Row>
        <Row>
          <div className="d-flex justify-content-center">
            <Button
              onClick={() => {
                setPageNumber(1);
                setCurrentPartner(null);
                setCPList([]);

                let payload = [
                  `pageNumber=1`,
                  `pageSize=${pageSize}`,
                  partnerCode !== '' && `partnerCode=${partnerCode}`,
                  pod !== '' && `pod=${pod}`
                ];

                getList(payload);
              }}
            >
              Caută
            </Button>
            <Button
              style={{ marginLeft: '5px' }}
              onClick={() => {
                setPod('');
                setPartnerCode('');
                setPageNumber(1);
                setPageSize(10);
                setCurrentPartner(null);
                setCPList([]);

                let payload = [`pageNumber=1`, `pageSize=10`];

                getList(payload);
              }}
            >
              Resetează
            </Button>
          </div>
        </Row>
      </div>
    );
  };

  return (
    <div className="m-3">
      {!isAdding && <Button onClick={() => setIsAdding(true)}>Adaugă punct consum</Button>}
      {isAdding && <AddPartnerCP getList={getList} handleSuccessModal={handleSuccessModal} />}
      <Row>
        <Col sm={12} md={6}>
          {list.length > 0 && (
            <div className="d-flex justify-content-end mb-2 mt-2">
              <ButtonFilters onClick={() => setToggleFilters((prev) => !prev)} toggled={toggleFilters} />
            </div>
          )}

          {toggleFilters && renderFilters()}
        </Col>
      </Row>

      <Row className="mt-2">
        <Col sm={12} md={6}>
          <div>
            <div className="general-table-container">
              <table className="w-100">
                <thead>
                  <tr>
                    <th>
                      <h6 className="m-0">Parteneri</h6>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {list.length > 0 &&
                    list.map((item: any, index: number) => (
                      <PartnerRow
                        item={item}
                        onSelect={onPartnerSelect}
                        key={index}
                        currentPartner={currentPartner}
                        onDelete={onPartnerDelete}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          {list.length > 0 && (
            <Pagination
              currentPage={pageNumber}
              totalElements={totalItems}
              perPage={pageSize}
              paginate={paginate}
              setElementsPerPage={setElementsPerPage}
            />
          )}

          {list.length === 0 && error === '' && <p className="text-center">Momentan nu aveți niciun partener.</p>}
          {error !== '' && <p className="text-center fw-bold red">{error}</p>}
        </Col>
        <Col sm={12} md={6}>
          <div>
            {cpList.length > 0 && (
              <div className="general-table-container">
                <table className="w-100">
                  <thead>
                    <tr>
                      <th>
                        <h6 className="m-0">Locuri de consum</h6>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {cpList.map((item, index) => (
                      <PartnerCPTableRow key={index} item={item} />
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {cpList.length === 0 && currentPartner !== null && cpError === '' && (
              <p className="text-center">Nu există niciun loc de consum asociat acestui partener.</p>
            )}
            {cpError !== '' && <p className="text-center fw-bold red">{cpError}</p>}
          </div>
          {cpList.length > 0 && (
            <Pagination
              currentPage={CPpageNumber}
              totalElements={CPtotalItems}
              perPage={CPpageSize}
              paginate={paginateCP}
              setElementsPerPage={setCPElementsPerPage}
            />
          )}
        </Col>
      </Row>

      {renderConfirmModal()}
      {renderSuccessModal()}
    </div>
  );
};

export default Partners;