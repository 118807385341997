import Pagination from '@components/common/Pagination/Pagination';
import SortAscDesc from '@components/common/Sort/SortAscDesc';
import { Fragment } from 'react';
import TableItem from './TableItem';

const Table = (props: {
  list: any[];
  currentPage: any;
  perPage: any;
  paginate: any;
  setElementsPerPage: any;
  sortBy: any;
  handleSortBy: any;
  sortType: any;
  handleSortType: any;
  totalItems: any;
  getDetails: any;
}) => {
  const { list, currentPage, perPage, paginate, setElementsPerPage, sortBy, handleSortBy, sortType, handleSortType, totalItems, getDetails } = props;

  return (
    <Fragment>
      <div className="general-table-container mt-2">
        <table className="w-100">
          <thead>
            <tr>
              <th>
                <SortAscDesc
                  sortColumn={sortBy}
                  sortAscending={sortType === 'ASC'}
                  onSort={() => {
                    if (sortBy !== 'codClient') {
                      handleSortBy('codClient');
                    } else {
                      if (sortType === 'DESC') {
                        handleSortType('ASC');
                      } else {
                        handleSortType('DESC');
                      }
                    }
                  }}
                  fieldId="codClient"
                  fieldName="Cod client"
                />
              </th>
              <th>
                <SortAscDesc
                  sortColumn={sortBy}
                  sortAscending={sortType === 'ASC'}
                  onSort={() => {
                    if (sortBy !== 'SAPNumber') {
                      handleSortBy('SAPNumber');
                    } else {
                      if (sortType === 'DESC') {
                        handleSortType('ASC');
                      } else {
                        handleSortType('DESC');
                      }
                    }
                  }}
                  fieldId="SAPNumber"
                  fieldName="Nr. cerere racordare"
                />
              </th>
              <th>
                <SortAscDesc
                  sortColumn={sortBy}
                  sortAscending={sortType === 'ASC'}
                  onSort={() => {
                    if (sortBy !== 'RequestDate') {
                      handleSortBy('RequestDate');
                    } else {
                      if (sortType === 'DESC') {
                        handleSortType('ASC');
                      } else {
                        handleSortType('DESC');
                      }
                    }
                  }}
                  fieldId="RequestDate"
                  fieldName="Dată inregistrare cerere racordare"
                />
              </th>
              <th>
                <h6 className="m-0">Nr. Aviz tenic de racordare / Aviz tehnic de principiu/ Notificare IU</h6>
              </th>
              <th>
                <h6 className="m-0">Dată emitere Aviz tehnic de racordare / Aviz tehnic de principiu Notificare IU</h6>
              </th>
              <th>
                <SortAscDesc
                  sortColumn={sortBy}
                  sortAscending={sortType === 'ASC'}
                  onSort={() => {
                    if (sortBy !== 'command') {
                      handleSortBy('command');
                    } else {
                      if (sortType === 'DESC') {
                        handleSortType('ASC');
                      } else {
                        handleSortType('DESC');
                      }
                    }
                  }}
                  fieldId="command"
                  fieldName="Tipul documentului emis (Aviz tehnic de racordare / Aviz tehnic de principiu Notificare IU)"
                />
              </th>
              <th>
                <h6 className="m-0">Acțiuni</h6>
              </th>
            </tr>
          </thead>
          <tbody>{list.length > 0 && list.map((item: any, index: number) => <TableItem key={index} item={item} getDetails={getDetails} />)}</tbody>
        </table>
        {list.length === 0 && <p className="d-flex mt-2 justify-content-center">Nu există date disponibile.</p>}
      </div>
      {list.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalElements={totalItems}
          perPage={perPage}
          paginate={paginate}
          setElementsPerPage={setElementsPerPage}
        />
      )}
    </Fragment>
  );
};

export default Table;