import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { Input } from '@components/common/Input';

const Search = (props: any) => {
  return (
    <div className="m-3">
      <Row className="justify-content-center">
        <Col xs={12} md={2}>
          <Input
            label={props.utility == 2 ? 'Număr loc consum' : 'Cod loc consum'}
            value={props.clc}
            onChange={(e) => {
              e.preventDefault();
              props.clcChange(e.target.value);
            }}
          />
        </Col>

        <Col xs={12} md={2}>
          <Input
            label={props.utility == 2 ? 'Cod loc consum' : 'POD'}
            value={props.pod}
            onChange={(e) => {
              e.preventDefault();
              props.podChange(e.target.value);
            }}
          />
        </Col>

        <Col xs={12} md={2}>
          <Input
            label={'Cod partener'}
            value={props.partnerCode}
            onChange={(e) => {
              e.preventDefault();
              props.partnerCodeChange(e.target.value);
            }}
          />
        </Col>

        <Col xs={12} md={2}>
          <Dropdown
            options={
              props.counties.map((option: any, index: number) => ({
                id: option.countyCode,
                name: option.countyName
              })) as DropdownOptionType[]
            }
            label={'Județ'}
            defaultPlaceholder={'Alege județul'}
            displaySearch={true}
            onChange={(value) => {
              props.handleCountyChange(value);
            }}
            value={props.county}
          />
        </Col>

        {props.county !== '' && props.localities && props.localities.length > 0 && (
          <Col xs={12} md={2}>
            <Dropdown
              options={
                props.localities.map((option: any, index: number) => ({
                  id: option.localityId,
                  name: option.localityName
                })) as DropdownOptionType[]
              }
              label={'Localitate'}
              defaultPlaceholder={'Alege localitatea'}
              displaySearch={true}
              onChange={(value) => {
                props.handleLocalityChange(value);
              }}
              value={props.locality}
            />
          </Col>
        )}
      </Row>

      <Row className="justify-content-center mb-3">
        <Col xs={12} sm={9}>
          <div className="triplet-separator"></div>
        </Col>
      </Row>
      <Row>
        <div className="d-flex justify-content-center">
          <Button
            onClick={() => props.submitSearch(props.utility)}
            disabled={!props.pod && !props.clc && !props.partnerCode && !props.county}
          >
            Caută
          </Button>
          <Button style={{ marginLeft: '5px' }} onClick={() => props.reset(props.utility)}>
            Reset
          </Button>
        </div>
      </Row>
    </div>
  );
};

export default Search;
